import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";

//npm imports
import styled, { withTheme } from "styled-components/macro";
import { useParams, useNavigate, Link } from "react-router-dom";

//component imports
import { useTranslation } from "react-i18next";
import InputField from "../../Components/InputField/InputField";
import SelectInput from "../../Components/SelectInput/SelectInput";
import {
  Caption12,
  HeaderMedium28,
  SpecialHeader36,
  Text18,
} from "../../Components/Text/Text";
import ButtonWide, {
  StyledButtonWide,
} from "../../Components/ButtonWide/ButtonWide";
import Checkbox from "../../Components/Checkbox/Checkbox";
import BrainsterNextLogo from "../../Components/BrainsterNextLogo/BrainsterNextLogo";
import ArrowBack from "../../Components/ArrowBack/ArrowBack";

//hooks
import useArrayLength from "../../Hooks/useArrayLength";

//other imports
import { GlobalContext } from "../../Context/GlobalContext";
import { API } from "../../Consts/Api";
import coin_full from "./Static/coin-full.png";
import coin_vertical from "./Static/coin-vertical.png";
import coin_horizontal from "./Static/coin-horizontal.png";
import coin_distorted from "./Static/coin-full-distorted.png";
import application_form_background from "./Static/application-form-background.png";
import application_form_background_mobile from "./Static/application-form-background-mobile.png";
import { nextHeader } from "./Static/nextHeader";
import { getReady } from "./Static/getReady";
import ButtonBasic from "../../Components/ButtonBasic/ButtonBasic";

const ApplicationFormWrapper = styled.section`
  display: flex;
  min-height: 100vh;
  position: relative;
  & > aside {
    width: 42.4%;
    position: relative;
    background-color: ${(props) => props.theme.violet};
    background-image: url(${application_form_background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    & > .logo-and-arrow-container {
      & > .brainster-logo {
        margin-left: 15%;
        margin-top: 60px;
      }
      .arrow-back {
        display: none;
      }
    }
    .header-container {
      width: 60%;
      position: absolute;
      top: 50%;
      margin-left: 15%;
      transform: translateY(-50%);
      display: block;
      p {
        text-align: center;
        margin-bottom: 12px;
      }
      svg {
        width: 100%;
      }
    }
    img {
      position: absolute;
    }
    .coin-full {
      top: 20%;
      left: 60%;
    }
    .coin-quarter {
      top: 0;
      right: -4px;
    }
    .coin-horizontal {
      bottom: 0;
      right: -50px;
      /* z-index: 9; */
    }
    .coin-vertical {
      top: 60%;
      left: 0;
    }
    .coin-distorted {
      top: 70%;
      left: 60%;
    }
  }
  .start-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(15px);
    display: ${(props) => (props.showPopup ? "flex" : "none")};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .start-popup {
      max-width: 793px;
      padding: 0px 40px 40px 40px;
      background-color: ${(props) => props.theme.white};
      border-radius: 8px;
      position: relative;
      .exit-popup-button {
        border: 1px solid transparent;
        border-bottom-color: ${(props) => props.theme.gray2};
        color: ${(props) => props.theme.gray2};
        font-size: 14px;
        line-height: 22px;
        background: transparent;
        padding: 0;
        margin-right: 40px;
        &:hover {
          cursor: pointer;
        }
      }
      .start-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        header {
          display: flex;
          align-items: center;
          align-self: flex-start;
          margin-left: 30px;
          & > svg {
            margin-top: -40px;
            margin-left: -100px;
            z-index: 9;
          }
        }
        & > div.buttons-container {
          align-self: flex-end;
        }
        & > p {
          margin: 16px 0px 40px 0px;
          color: ${(props) => props.theme.gray2};
        }
      }
      img {
        position: absolute;
        bottom: -2px;
        left: -20px;
      }
    }
  }
  & > main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 57.6%;
    background-color: ${(props) => props.theme.black};
    /* z-index: 3; */
  }
  @media (max-width: 825px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${(props) => props.theme.veryLightGray};
    & > aside {
      width: 100%;
      height: 290px;
      background-color: ${(props) => props.theme.violet};
      background-image: url(${application_form_background_mobile});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      .logo-and-arrow-container {
        width: 82%;
        margin: 32px auto;
      }
      .header-container {
        left: 0%;
        transform: translate(0%, -50%);
        margin-left: 0;
        z-index: 10;
      }
      & > .logo-and-arrow-container {
        display: flex;
        justify-content: space-between;
        .arrow-back {
          display: block;
          &:hover {
            cursor: pointer;
          }
        }
        & > .brainster-logo {
          margin: 0;
        }
      }

      .coin-full,
      .coin-distorted,
      .coin-quarter,
      .coin-horizontal {
        display: none;
      }
      .coin-vertical {
        top: unset;
        left: unset;
        bottom: -50px;
        right: 0;
        transform: scaleX(-1);
        z-index: 9;
      }
    }
    & > main {
      width: 100%;
      min-height: 75vh;
      form {
        ${HeaderMedium28} {
          font-size: 24px;
          margin-bottom: 40px;
        }
      }
    }
  }
  @media (max-width: 550px) {
    & > aside {
      .header-container-mobile,
      .logo-and-arrow-container {
        width: 92%;
      }
      .coin-vertical {
        display: none;
      }
    }
    & > main {
      align-items: flex-start;
    }
  }
`;

const StyledApplicationForm = styled.form`
  padding-top: 35px;
  width: 71.4%;
  ${HeaderMedium28} {
    width: 82%;
    margin-bottom: 60px;
  }
  .birth-date-container {
    .birthDateInputsContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
    }
  }
  .name-inputs-container,
  .email-and-phone-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .email-and-phone-container {
    margin-bottom: 36px;
  }
  ${Caption12}.error {
    color: ${(props) => props.theme.textError};
    height: 14px;
    margin-top: 8px;
  }
  & > ${Caption12} {
    margin-bottom: 16px;
  }
  ${StyledButtonWide} {
    margin-top: 32px;
    margin-bottom: 70px;
  }
  @media (max-width: 1250px) {
    width: 78%;
  }

  @media (max-width: 1250px) {
    width: 82%;
  }

  @media (max-width: 1015px) {
    ${HeaderMedium28} {
      width: 100%;
    }
  }

  @media (max-width: 550px) {
    width: 92%;
    .birth-date-container {
      .birthDateInputsContainer {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        .select-input__single-value {
          padding-left: 4px;
        }
        .select-input__value-container {
          padding: 2px 4px;
        }
        .select-input__indicators {
          margin-right: 8px;
          .select-input__indicator {
            padding: 0;
          }
          svg {
            transform: scale(0.99);
          }
        }
      }
    }
    .name-inputs-container,
    .email-and-phone-container {
      grid-template-columns: 1fr;
      gap: 0px;
    }
    .name-inputs-container,
    .birth-date-container {
      margin-bottom: 0px;
      .css-1hb7zxy-IndicatorsContainer {
        width: 25px;
      }
    }
    .email-and-phone-container {
      margin-bottom: 16px;
    }
    .error {
    }
    ${StyledButtonWide} {
      margin-top: 24px;
      margin-bottom: 40px;
    }
  }
`;

const ApplicationForm = (props) => {
  const { t } = useTranslation();
  const {
    userInfo,
    academies,
    setUserInfo,
    termsAgreement,
    setTermsAgreement,
    showAllRulesPopup,
    setShowAllRulesPopup,
    checkIfClickedOutside,
  } = useContext(GlobalContext);
  //The birthDate is handled manually becuase it consists of 3 different fields and the
  //element #birthDateError displays the errors if there are any
  const resetErrors = {
    name: [],
    surname: [],
    email: [],
    highschool: [],
    birthDate: [],
    academyId: [],
    no_term: [],
    limit_exceeded: [],
  };
  const [errors, setErrors] = useState(resetErrors);

  const popupRef = useRef();
  const [showPopup, setShowPopup] = useState(false);

  const nameInputRef = useRef();
  const surnameInputRef = useRef();
  const emailInputRef = useRef();
  const phoneInputRef = useRef();
  const highschoolInputRef = useRef();

  //needed for select inputs as options and for updating userInfo.birthDate
  const [days, setDays] = useState([
    { value: null, label: t("application_form.form.birth_date.day") },
  ]);
  const [selectedDay, setSelectedDay] = useState(days[0]);
  const [months, setMonths] = useState([
    { value: null, label: t("application_form.form.birth_date.month") },
  ]);
  const [selectedMonth, setSelectedMonth] = useState(months[0]);
  const [years, setYears] = useState([
    { value: null, label: t("application_form.form.birth_date.year") },
  ]);
  const [selectedYear, SetSelectedYear] = useState(years[0]);

  const formRef = useRef();

  let params = useParams();
  let navigate = useNavigate();

  const allMonths = useMemo(
    () => [
      t("application_form.form.birth_date.all_months.jan"),
      t("application_form.form.birth_date.all_months.feb"),
      t("application_form.form.birth_date.all_months.mar"),
      t("application_form.form.birth_date.all_months.apr"),
      t("application_form.form.birth_date.all_months.may"),
      t("application_form.form.birth_date.all_months.jun"),
      t("application_form.form.birth_date.all_months.jul"),
      t("application_form.form.birth_date.all_months.aug"),
      t("application_form.form.birth_date.all_months.sep"),
      t("application_form.form.birth_date.all_months.oct"),
      t("application_form.form.birth_date.all_months.nov"),
      t("application_form.form.birth_date.all_months.dec"),
    ],
    [t]
  );
  useEffect(() => {
    let days = [
      { value: null, label: t("application_form.form.birth_date.day") },
    ];
    for (let i = 0; i < 31; i++) {
      days.push({ value: i + 1, label: i + 1 });
    }
    let mnths = [
      { value: null, label: t("application_form.form.birth_date.month") },
    ];
    allMonths.forEach((month, index) => {
      mnths.push({ value: index + 1, label: month });
    });
    let year = new Date();
    let years = [
      { value: null, label: t("application_form.form.birth_date.year") },
    ];
    for (let i = year.getFullYear() - 17; i > 1999; i--) {
      years.push({ value: i, label: i });
    }
    setDays(days);
    setMonths(mnths);
    setYears(years);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUserInfo({
      ...userInfo,
      birthDate: { ...userInfo.birthDate, day: selectedDay.value },
    });
    //eslint-disable-next-line
  }, [selectedDay.value, setUserInfo]);
  useEffect(() => {
    setUserInfo({
      ...userInfo,
      birthDate: { ...userInfo.birthDate, month: selectedMonth.value },
    });
    //eslint-disable-next-line
  }, [selectedMonth.value, setUserInfo]);
  useEffect(() => {
    setUserInfo({
      ...userInfo,
      birthDate: { ...userInfo.birthDate, year: selectedYear.value },
    });
    //eslint-disable-next-line
  }, [selectedYear.value, setUserInfo]);



  const applyStudent = async (e, userInfo, academyId) => {
    e.preventDefault();
    const queryString = localStorage.getItem("queryString");

    if (termsAgreement) {
      let user = {
        email: userInfo.email,
        phone: userInfo.phone,
        query_string: queryString || null,
        referred_by: params.referralGuid || userInfo.referred_by || null,
      };

      API.axios
        .post(API.createApiRoute("user"), user)
        .then((response) => {
          if (response.data.success) {
            if (response.data.data.guid) {
              setUserInfo({
                ...userInfo,
                guid: response.data.data.guid,
                academy_id: null,
              });
              localStorage.setItem("guid", response.data.data.guid);
              setShowPopup(true);
              localStorage.removeItem("queryString");
              // navigate(API.createRoute("successApplication"))
        
            }
          } else {
            setErrors({ ...errors, no_term: [response.data.message] });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 422) {
            setErrors({
              ...resetErrors,
              ...error.response.data.errors,
              limit_exceeded: error.response.data.errors?.additional || [],
              birthDate: [
                error.response.data.errors["birth_date.day"] ||
                error.response.data.errors["birth_date.month"] ||
                error.response.data.errors["birth_date.year"],
              ],
            });
          } else {
            setErrors({ ...errors, ...error.response.data.errors?.additional });
          }
        });
    } else {
      setErrors({ ...errors, terms: t("errors.terms") });
    }
  };

  useEffect(() => {
    errors.terms && termsAgreement && setErrors({ ...errors, terms: false });
    //eslint-disable-next-line
  }, [errors.terms, termsAgreement]);

  useEffect(() => {
    !userInfo.academyId &&
      params.academyId &&
      setUserInfo({ ...userInfo, academyId: params.academyId });
    //eslint-disable-next-line
  }, [params, userInfo.academyId, setUserInfo]);

  //if there is data about the user in backend already
  //repopulate inputs with that data
  useEffect(() => {
    if (
      userInfo.name !== "" &&
      userInfo.name !== undefined &&
      nameInputRef.current
    ) {
      nameInputRef.current.value = userInfo.name;
    }
    if (
      userInfo.surname !== "" &&
      userInfo.surname !== undefined &&
      nameInputRef.current
    ) {
      surnameInputRef.current.value = userInfo.surname;
    }
    if (
      userInfo.email !== "" &&
      userInfo.email !== undefined &&
      nameInputRef.current
    ) {
      emailInputRef.current.value = userInfo.email;
    }
    if (
      userInfo.highschool !== "" &&
      userInfo.highschool !== undefined &&
      nameInputRef.current
    ) {
      highschoolInputRef.current.value = userInfo.highschool;
    }
    if (userInfo.birthDate?.day) {
      let days = [
        { value: null, label: t("application_form.form.birth_date.day") },
      ];
      for (let i = 0; i < 31; i++) {
        days.push({ value: i + 1, label: i + 1 });
      }
      days.forEach(
        (day) => day.value === userInfo.birthDate.day && setSelectedDay(day)
      );
    }
    if (userInfo.birthDate?.day) {
      let mnths = [
        { value: null, label: t("application_form.form.birth_date.month") },
      ];
      allMonths.forEach((month, index) => {
        mnths.push({ value: index + 1, label: month });
      });

      mnths.forEach(
        (month) =>
          month.value === userInfo.birthDate.month && setSelectedMonth(month)
      );
    }
    if (userInfo.birthDate?.year) {
      let year = new Date();
      let years = [
        { value: null, label: t("application_form.form.birth_date.year") },
      ];
      for (let i = year.getFullYear() - 13; i > 1940; i--) {
        years.push({ value: i, label: i });
      }

      years.forEach(
        (year) =>
          year.value === userInfo.birthDate.year && SetSelectedYear(year)
      );
    }

    if (
      userInfo.phone !== "" &&
      userInfo.phone !== undefined &&
      phoneInputRef.current
    ) {
      phoneInputRef.current.value = userInfo.phone;
    }
  }, [
    userInfo.name,
    userInfo.surname,
    userInfo.email,
    userInfo.phone,
    userInfo.highschool,
    userInfo.birthDate?.day,
    userInfo.birthDate?.month,
    userInfo.birthDate?.year,
    userInfo.academyId,
    setSelectedDay,
    SetSelectedYear,
    setSelectedMonth,
    allMonths,
    t,
  ]);

  //if there is an error on the users name input and user makes a change in name input, remove the error
  useEffect(() => {
    errors.name.length > 0 && setErrors({ ...errors, name: [] });
    //eslint-disable-next-line
  }, [userInfo.name]);
  //if there is an error on the users surname input and user makes a change in surname input, remove the error
  useEffect(() => {
    errors.surname.length > 0 && setErrors({ ...errors, surname: [] });
    //eslint-disable-next-line
  }, [userInfo.surname]);
  //if there is an error on the users email input and user makes a change in email input, remove the error
  useEffect(() => {
    errors.email.length > 0 && setErrors({ ...errors, email: [] });
    //eslint-disable-next-line
  }, [userInfo.email]);

  useEffect(() => {
    errors.email.length > 0 && setErrors({ ...errors, email: [] });
    //eslint-disable-next-line
  }, [userInfo.email]);

  useEffect(() => {
    errors.highschool.length > 0 && setErrors({ ...errors, highschool: [] });
    //eslint-disable-next-line
  }, [userInfo.highschool]);

  const submitOnEnter = useCallback(
    (e) => {
      //it triggers by pressing the enter key
      if (e.keyCode === 13) {
        applyStudent(e, userInfo, params.academyId);
      }
      //eslint-disable-next-line
    },
    [applyStudent, params.academyId]
  );

  useEffect(() => {
    window.addEventListener("keydown", submitOnEnter);

    return () => {
      window.removeEventListener("keydown", submitOnEnter);
    };
  }, [submitOnEnter]);

  return (
    <ApplicationFormWrapper showPopup={showPopup}>
      <aside>
        <div className="logo-and-arrow-container">
          <Link to={API.createRoute("home")}>
            <ArrowBack color={props.theme.white} />
          </Link>
          <BrainsterNextLogo
            color={props.theme.white}
            className="brainster-logo"
          />
        </div>
        <div className="header-container ">
          <SpecialHeader36 color={props.theme.white}>
            {t("application_form.join")}
          </SpecialHeader36>
          <div>{nextHeader}</div>
        </div>
        <img className="coin-full" src={coin_full} alt="coin" />
        {/* <img className='coin-horizontal' src={coin_horizontal} alt="coin" /> */}
        <img className="coin-vertical" src={coin_vertical} alt="coin" />
        <img className="coin-distorted" src={coin_distorted} alt="coin" />
      </aside>
      <main>
        <StyledApplicationForm
          ref={formRef}
          onSubmit={(e) => applyStudent(e, userInfo, params.academyId)}
        >
          <HeaderMedium28 color={props.theme.danger_select}>
            {t("application_form.header")}
          </HeaderMedium28>
          <div className="email-container">
            <InputField
              type="email"
              required
              name="email"
              ref={emailInputRef}
              onChange={() =>
                setUserInfo({ ...userInfo, email: emailInputRef.current.value })
              }
              autocomplete="email"
              label={t("application_form.form.email")}
              placeholder={t("application_form.form.email")}
              value={emailInputRef.current?.value}
              className="email-input-container"
              errors={errors.email}
            />
          </div>
          <div>
            <InputField
              required
              type="tel"
              name="tel"
              ref={phoneInputRef}
              onChange={() =>
                setUserInfo({ ...userInfo, phone: phoneInputRef.current.value })
              }
              autocomplete="tel"
              label={t("registration_form.form.phone")}
              placeholder={t("registration_form.form.phone")}
              value={phoneInputRef.current?.value}
              className="phone-input-container"
              errors={errors.phone}
            />
          </div>
          <Caption12 color={props.theme.textError}>
            {useArrayLength(errors.no_term) &&
              errors.no_term.map((error, index) => (
                <span key={index}>
                  <span>{error}</span>
                  <br />
                </span>
              ))}
          </Caption12>
          <Caption12 color={props.theme.textError}>
            {useArrayLength(errors.limit_exceeded) &&
              errors.limit_exceeded.map((error, index) => (
                <span key={index}>
                  <span>{error}</span>
                  <br />
                </span>
              ))}
          </Caption12>
          <Checkbox
            text={t("application_form.form.you_agree")}
            checked={termsAgreement}
            error={errors.terms}
            onClick={() => setTermsAgreement(!termsAgreement)}
            link={{
              text: t("general.terms_and_conditions"),
              onClick: () =>
                setShowAllRulesPopup({
                  ...showAllRulesPopup,
                  display: true,
                  privacy: true,
                }),
            }}
          />
          <ButtonWide
            type="submit"
            text={t("general.continue")}
            disabled={!termsAgreement}
          />
        </StyledApplicationForm>
      </main>

      {/* ------------ Get Ready Modal ------------------- */}

      <div onClick={() => setShowPopup()} className="start-popup-container">
        <div ref={popupRef} className="start-popup">
          <div className="start-popup-content">
            <header>{getReady}</header>
            <Text18>
              {t("rules_agreement.get_ready_popup.description")
                .replace(
                  "{ASSESSMENT_DURATION}",
                  academies.length > 0 &&
                  academies.filter(
                    (academy) => academy.id === parseFloat(params.academyId)
                  )[0]?.duration / 60
                )
                .replace(
                  "{QUESTION_NUMBER}",
                  academies.length > 0 &&
                  academies.filter(
                    (academy) => academy.id === parseFloat(params.academyId)
                  )[0]?.question_number
                )}
            </Text18>
            <div className="buttons-container">
              <ButtonBasic
                variation="large"
                filling={"black"}
                onClick={() => [
                  setUserInfo({ ...userInfo, academy_id: params.academyId }),
                  navigate(API.createRoute("assessment")),
                ]}
                text={t("rules_agreement.get_ready_popup.start_cta")}
              />
            </div>
          </div>
        </div>
      </div>
    </ApplicationFormWrapper>
  );
};

export default withTheme(ApplicationForm);
