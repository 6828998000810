import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { collegeGroupStatus } from "../../../AwardsPending/AwardsPending";
import { Text12, TextCustom } from "../../../../Components/Text/Text";
import theme from "../../../../Consts/Theme";
import heroTitleOneMobile from "./Static/hero_title_one_mobile.svg";
import heroTitleTwoMobile from "./Static/hero_title_two_mobile.svg";
import heroTitleThreeMobile from "./Static/hero_title_three_mobile.svg";
import academyYearMobile from "./Static/academy_year_mobile.svg";
import flameIcon from "./Static/flame.svg";
import FullGroup from "../../../AwardsPending/Blocks/FullGroup";
import HeroCardNumbers from "./HeroCardNumbers";
import ButtonBasic from "../../../../Components/ButtonBasic/ButtonBasic";
import Wrapper from "../../../../Components/Wrapper/Wrapper";

// styled-components
const StyledContainer = styled.section`
  background-color: ${theme.lightBlack};
  padding-top: 160px;
  padding-bottom: 60px;
`;

const HeadingTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .ml-12 {
    margin-left: 12px;
  }

  .img-title {
    width: 65%;
  }

  div.img-student-1 {
    width: 56px;
  }
  div.img-student-1 img {
    width: 100%;
    display: block;
    object-fit: contain;
  }

  @media (min-width: 601px) and (max-width: 769px) {
    padding-left: 100px;
    img.img-title {
      width: 50%;
    }
  }
`;
const HeadingTitlePartTwo = styled.div`
  width: 100%;
  margin-bottom: 16px;

  img {
    display: block;
    width: 100%;
  }

  @media (min-width: 601px) and (max-width: 769px) {
    padding-left: 80px;
    img.img-title {
      width: 73%;
    }
  }
`;
const HeadingTitlePartThree = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  .img-title {
    width: 50%;
  }

  div.img-student-2 {
    position: absolute;
    width: 85px;
    right: 0;
    top: 0;
  }
  div.img-student-2 img {
    width: 100%;
    display: block;
    object-fit: contain;
  }
  @media (min-width: 601px) and (max-width: 769px) {
    justify-content: center;
    img.img-title {
      width: 30%;
    }

    div.img-student-2 {
      position: relative;
      margin-left: 40px;
    }
  }
`;
const HeadingTitlePartFour = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .academy-year {
    align-self: self-end;
    width: 60%;
  }
  div.img-student-3 {
    width: 70px;
  }

  div.img-student-3 img {
    width: 100%;
    display: block;
    object-fit: contain;
  }

  @media (min-width: 601px) and (max-width: 769px) {
    justify-content: center;
    margin-top: 16px;

    img.academy-year {
      width: 40%;
      align-self: center;
      margin-left: 20px;
    }
  }
`;
const StyledBNextInfoContainer = styled.section`
  margin-top: 30px;

  p.about-next-description {
    text-align: justify;
    margin-bottom: 12px;
  }

  div.content-block-cta {
    border-bottom: 1px solid white;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid ${theme.pink};
      color: ${theme.pink};
    }
  }

  @media (min-width: 601px) and (max-width: 769px) {
    p.about-next-description {
      text-align: center;
    }
  }
`;
const StyledGroupStatusWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -35px;
  margin-bottom: 6px;

  div.group-status:first-child {
    margin-right: 8px;
  }

  @media (max-width: 1024px) {
    margin-top: 130px;
  }
  @media (max-width: 992px) {
    margin-top: 35px;
    justify-content: center;
  }
`;

const StyledGroupStats = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;

  .test-takers-box {
    display: flex;
    align-items: center;
    position: relative;
  }
  .test-takers-box img.white-doodle {
    position: absolute;
    top: 100%;
    left: 12px;
  }
  .test-takers-box span {
    background: linear-gradient(166deg, #7497ff 7.38%, #e6e7eb 88.62%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-right: 4px;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
  }
`;
const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  button {
    margin-top: 20px;
    margin-bottom: 16px;
    font-size: 15px;
    width: 100%;
  }


`;
const HomepageHeroMobile = ({
  handleScrollToSection,
  landingNumbers,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <Wrapper>
        {/* --------------------------------- Main Heading ---------------------- */}

        <header>
          {/*  ------- Heading title part one ------- */}
          <HeadingTitleContainer>
            <div className="img-student-1">
              <img src="/student-1-mobile.png" alt="Student Image" />
            </div>

            <img
              src={heroTitleOneMobile}
              alt="Heading Title"
              className="ml-12 img-title"
              width="249"
              height="34"
            />
          </HeadingTitleContainer>
          {/*  ------- Heading title part two ------- */}
          <HeadingTitlePartTwo>
            <img
              src={heroTitleTwoMobile}
              alt="Heading Title"
              className="img-title"
              width="383"
              height="32"
            />
          </HeadingTitlePartTwo>
          {/*  ------- Heading title part three ------- */}
          <HeadingTitlePartThree>
            <img
              src={heroTitleThreeMobile}
              alt="Heading Title"
              className="img-title"
              width="191"
              height="34"
            />
            <div className="img-student-2">
              <img src="/student-2-mobile.png" alt="Student Image" />
            </div>
          </HeadingTitlePartThree>
          {/*  ------- Heading title part four ------- */}
          <HeadingTitlePartFour>
            <div className="img-student-3">
              <img src="/student-3-mobile.png" alt="Student Image" />
            </div>
            <img
              src={academyYearMobile}
              alt="Heading Title"
              className="academy-year"
            />
          </HeadingTitlePartFour>
        </header>
        {/* --------------------------------- Brainster Next Info ---------------------- */}
        <StyledBNextInfoContainer>
          <TextCustom
            className="about-next-description"
            color="white"
            lineHeight="27"
            dangerouslySetInnerHTML={{
              __html: t(
                `homepage.hero_section.content_about_next.description_mobile`
              ),
            }}
          ></TextCustom>
          <div className="content-block-cta" onClick={handleScrollToSection}>
            <TextCustom color={theme.white} fontWeight={700} lineHeight="24">
              {t("homepage.hero_section.content_about_next.cta_info_open")}
            </TextCustom>
          </div>
        </StyledBNextInfoContainer>
        {/* ----------------------------- Filled Groups ---------------------------- */}
        <section>
          {/* <StyledGroupStatusWrap>
            {collegeGroupStatus.mobile?.map((group) => {
              if (group.status === "full") {
                return (
                  <div key={group.id} className="group-status">
                    <FullGroup
                      text={group.name}
                      border="1px solid #61626D"
                      color={theme.gray2}
                      bgColor="linear-gradient(104deg, #161616 0%, #2C2C31 104.95%)"
                    />
                  </div>
                );
              }
            })}
          </StyledGroupStatusWrap> */}
          {/* ------------ Statistics ------------ */}
          <StyledGroupStats>
            <HeroCardNumbers
              numbers={landingNumbers?.test_takers}
              content={t("homepage.hero_section.hero_card_mobile.test_takers")}
              bgColor={theme.black3}
              // preHeading={t("homepage.hero_section.available_groups")}
            />
            {/* <div className="test-takers-box">
              <img src={flameIcon} alt="Flame Icon" className="" />
              <span> {landingNumbers?.test_takers}</span>
              <Text12 color={theme.gray1} className="test-takers-text">
                {t("homepage.hero_section.hero_card_mobile.test_takers")}
              </Text12>
            </div> */}
          </StyledGroupStats>
        </section>
        {/* ----------------------------- CTA Footer ---------------------------- */}
        <StyledFooter>
          <ButtonBasic
            variation="extra-large"
            filling="altBlue"
            text={t("homepage.greatest_challenge_in_life.cta")}
            onClick={handleScrollToSection}
          />
        </StyledFooter>
      </Wrapper>
    </StyledContainer>
  );
};

export default HomepageHeroMobile;
